<template>
  <nav class="navbar navbar-expand-lg navbar-dark fixed-top" :class="bgNavbar">
    <div class="container-fluid">
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarTogglerDemo"
        aria-controls="navbarTogglerDemo"
        aria-expanded="false"
        aria-label="Toggle navigation"
        @click="timeoutHide()"
        v-if="activeModeCount > 0"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <a class="navbar-brand" href="#">
        CheckIn-App
        <img
          src="../assets/img/datasport_logo.svg"
          alt=""
          width="24"
          height="24"
          class="d-inline-block align-text-top ms-1"
          style="filter: brightness(0) invert(1)"
        />
      </a>
      <button
        type="button"
        class="btn"
        :class="apiUrl_button"
        data-bs-toggle="offcanvas"
        href="#offcanvasExample"
        aria-controls="offcanvasExample"
        style="float: right"
      >
        <font-awesome-icon :icon="['fas', 'user']" />
      </button>
      <div
        class="collapse navbar-collapse"
        id="navbarTogglerDemo"
        :class="!visible ? 'collapse' : ''"
      >
        <ul class="navbar-nav ms-auto">
          <li class="nav-item" v-if="activeMode.includes('EinzelCheckIn')">
            <a
              class="nav-link"
              href="#standard"
              @click="setView('EinzelCheckIn')"
              style="font-size: 120%"
              >Check-In Standard</a
            >
          </li>
          <li class="nav-item" v-if="activeMode.includes('Nachmelden')">
            <a
              class="nav-link"
              href="#standard"
              @click="setView('Nachmelden')"
              style="font-size: 120%"
              >Check-In Nachmelden</a
            >
          </li>
          <li class="nav-item" v-if="activeMode.includes('BDR')">
            <a
              class="nav-link"
              href="#BDR"
              @click="setView('BDR')"
              style="font-size: 120%"
              >Check-In BDR</a
            >
          </li>
          <li class="nav-item" v-if="activeMode.includes('OrderOption')">
            <a
              class="nav-link"
              href="#order"
              @click="setView('OrderOption')"
              style="font-size: 120%"
              >Ausgabe Bestelloptionen</a
            >
          </li>
          <li class="nav-item" v-if="activeMode.includes('Gepaeck')">
            <a
              class="nav-link"
              href="#gepaeck"
              @click="setView('Gepaeck')"
              style="font-size: 120%"
              >Gepäckaufgabe</a
            >
          </li>
          <li class="nav-item" v-if="activeMode.includes('Startkontrolle')">
            <a
              class="nav-link"
              href="#kontrolle"
              @click="setView('Startkontrolle')"
              style="font-size: 120%"
              >Startkontrolle</a
            >
          </li>
          <li class="nav-item" v-if="activeMode.includes('RadamRing')">
            <a
              class="nav-link"
              href="#radamring"
              @click="setView('RadamRing')"
              style="font-size: 120%"
              >Rad am Ring</a
            >
          </li>
          <li class="nav-item" v-if="activeMode.includes('Trackermanagement')">
            <a
              class="nav-link"
              href="#tracker"
              @click="setView('Trackermanagement')"
              style="font-size: 120%"
              >Trackermanagement</a
            >
          </li>
          <li class="nav-item" v-if="activeMode.includes('Chipmanagement')">
            <a
              class="nav-link"
              href="#chip"
              @click="setView('Chipmanagement')"
              style="font-size: 120%"
              >Chipmanagement</a
            >
          </li>
          <li class="nav-item" v-if="activeMode.includes('Einlass')">
            <a
              class="nav-link"
              href="#einlass"
              @click="setView('Einlass')"
              style="font-size: 120%"
              >Einlass</a
            >
          </li>
          <li class="nav-item" v-if="activeMode.includes('Ergebniss')">
            <a
              class="nav-link"
              href="#ergebniss"
              @click="setView('Ergebniss')"
              style="font-size: 120%"
              >Ergebnis</a
            >
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import Store from "@/store";

import { useToast } from "vue-toastification";

export default {
  name: "TheNavbar",
  props: {
    options: {
      type: Object,
      default: () => ({
        updateExists: false,
      }),
    },
  },
  data() {
    return {
      visible: false,
      isOnLine: navigator.onLine,

      toast: useToast(),
    };
  },
  computed: {
    //...mapGetters({ cartSum: "cartTotal" }), // wenn auf andere Variable übergeben wird
    activeMode() {
      return Store.getters.activeMode();
    },
    activeModeCount() {
      return Store.getters.activeMode().length;
    },
    bgNavbar() {
      if (this.options.updateExists) {
        return "bg-success";
      }
      if (this.isOnLine) {
        return "bg-dark";
      }
      return "bg-danger";
    },
    apiUrl() {
      return Store.getters.activeAPIUrl();
    },
    apiUrl_button() {
      if (this.apiUrl == "https://app.pani.cloud") {
        return "btn-light";
      } else {
        return "btn-warning";
      }
    },
  },
  methods: {
    setView(view) {
      this.visible = !this.visible;
      Store.mutations.setActiveView(view);
      this.$emit("modus-updated", { newModus: "0" });
    },
    async signout() {
      try {
        await this.$store.dispatch("signout");
        this.$router.push("/");
      } catch (error) {
        console.log(error);
      }
    },
    timeoutHide() {
      setTimeout(() => {
        this.visible = !this.visible;
      }, 5000);
    },
  },
  setup() {
    // setzte den Gerätestatus
    Store.mutations.setOnline(navigator.onLine);
    console.log("Neustart, setzte offlinestatus", navigator.onLine);
  },
  mounted() {
    window.addEventListener("online", () => {
      this.isOnLine = true;
      Store.mutations.setOnline(this.isOnLine);
      this.toast.success("Gerät ist wieder online!");
    });
    window.addEventListener("offline", () => {
      this.isOnLine = false;
      Store.mutations.setOnline(this.isOnLine);
      this.toast.error("Gerät ist offline!");
    });
  },
};
</script>

<style scoped></style>
