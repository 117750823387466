<template>
  <div id="app">
    <TheNavbar
      @modus-updated="modusStatus"
      :options="{ updateExists: updateExists }"
    ></TheNavbar>
    <div class="container-fluid" style="--bs-gutter-x: 0; margin-top: 56px">
      <div class="row">
        <div
          class="col-12 col-lg-3"
          :class="[mode === '0' ? '' : 'd-none d-sm-block']"
        >
          <TheLogin
            v-if="activeview == 'Login'"
            @modus-updated="modusStatus"
          ></TheLogin>
          <TheSelectionCheckIn
            v-if="activeview == 'EinzelCheckIn'"
            @modus-updated="modusStatusEinzel"
            @modus-search="modusSearchEinzel"
          ></TheSelectionCheckIn>
          <TheSelectionNachmelden
            v-if="activeview == 'Nachmelden'"
            @modus-updated="modusStatus"
          ></TheSelectionNachmelden>
          <TheSelectionOrderOption
            v-if="activeview == 'OrderOption'"
            @modus-updated="modusStatus"
          ></TheSelectionOrderOption>
          <TheSelectionBDR
            v-if="activeview == 'BDR'"
            @modus-updated="modusStatusBDR"
          ></TheSelectionBDR>
          <TheSelectionGepaeck
            v-if="activeview == 'Gepaeck'"
            @modus-updated="modusStatus"
          ></TheSelectionGepaeck>
          <TheSelectionStartkontrolle
            v-if="activeview == 'Startkontrolle'"
            @modus-updated="modusStatus"
          ></TheSelectionStartkontrolle>
          <TheSelectionRadamRing
            v-if="activeview == 'RadamRing'"
            @modus-updated="modusStatus"
          ></TheSelectionRadamRing>
          <TheSelectionTrackermanagement
            v-if="activeview == 'Trackermanagement'"
            @modus-updated="modusStatus"
          ></TheSelectionTrackermanagement>
          <TheSelectionChipmanagement
            v-if="activeview == 'Chipmanagement'"
            @modus-updated="modusStatus"
          ></TheSelectionChipmanagement>
          <TheSelectionEinlass
            v-if="activeview == 'Einlass'"
            @modus-updated="modusStatus"
          ></TheSelectionEinlass>
          <TheSelectionErgebniss
            v-if="activeview == 'Ergebniss'"
            @modus-updated="modusStatus"
          ></TheSelectionErgebniss>
        </div>
        <div class="col-12 col-lg-9">
          <div class="m-1" v-if="activeview == 'EinzelCheckIn'">
            <TheEinzelCheckIn
              :search="inputSearch"
              @modus-updated="modusStatus"
              v-if="mode === 'Einzel'"
            ></TheEinzelCheckIn>
            <TheSammelCheckIn
              @modus-updated="modusStatus"
              v-if="mode === 'Sammel'"
            ></TheSammelCheckIn>
            <TheTeamCheckIn
              @modus-updated="modusStatus"
              v-if="mode === 'Team'"
            ></TheTeamCheckIn>
          </div>
          <div class="m-1" v-if="activeview == 'Nachmelden'">
            <TheEinzelNachmelden
              :search="inputSearch"
              @modus-updated="modusStatus"
              v-if="mode === 'Einzel'"
            ></TheEinzelNachmelden>
            <TheListeNachmelden
              :search="inputSearch"
              @modus-updated="modusStatus"
              v-if="mode === 'Liste'"
            ></TheListeNachmelden>
            <TheDirektNachmelden
              :search="inputSearch"
              @modus-updated="modusStatus"
              v-if="mode === 'Direkt'"
            ></TheDirektNachmelden>
          </div>
          <div class="m-1" v-if="activeview == 'OrderOption'">
            <TheEinzelOrderOption
              v-if="mode.substring(0, 2) == 'OO'"
              @modus-updated="modusStatus"
            ></TheEinzelOrderOption>
          </div>
          <div class="m-1" v-if="activeview == 'BDR'">
            <TheBDRTeamCheckIn
              v-if="mode == '1'"
              @modus-updated="modusStatusBDR"
            ></TheBDRTeamCheckIn>
            <TheBDRRegistrierungCheckIn
              v-if="mode == '2'"
              @modus-updated="modusStatusBDR"
            ></TheBDRRegistrierungCheckIn>
            <TheBDRNMCheckIn
              v-if="mode == '3'"
              @modus-updated="modusStatusBDR"
            ></TheBDRNMCheckIn>
          </div>
          <div class="m-1" v-if="activeview == 'Gepaeck'">
            <TheGepaeckAufAusgabePlatz
              v-if="mode == 'AufAusgabePlatz'"
              @modus-updated="modusStatus"
            ></TheGepaeckAufAusgabePlatz>
            <TheGepaeckAufAusgabe
              v-if="mode == 'AufAusgabe'"
              @modus-updated="modusStatus"
            ></TheGepaeckAufAusgabe>
            <TheGepaeckInfo
              v-if="mode == 'Info'"
              @modus-updated="modusStatus"
            ></TheGepaeckInfo>
          </div>
          <div class="m-1" v-if="activeview == 'Startkontrolle'">
            <TheStartkontrolleScannen
              v-if="mode == 'Scannen'"
              @modus-updated="modusStatus"
            ></TheStartkontrolleScannen>
            <TheStartkontrolleInfo
              v-if="mode == 'Info'"
              @modus-updated="modusStatus"
            ></TheStartkontrolleInfo>
          </div>
          <div class="m-1" v-if="activeview == 'RadamRing'">
            <TheRadamRingScannen
              v-if="mode == 'Scannen'"
              @modus-updated="modusStatus"
            ></TheRadamRingScannen>
            <TheRadamRingInfo
              v-if="mode == 'Info'"
              @modus-updated="modusStatus"
            ></TheRadamRingInfo>
          </div>
          <div class="m-1" v-if="activeview == 'Trackermanagement'">
            <TheTrackermanagementScannen
              v-if="mode == 'Zuordnen'"
              @modus-updated="modusStatus"
            ></TheTrackermanagementScannen>
            <TheTrackermanagementInfo
              v-if="mode == 'Info'"
              @modus-updated="modusStatus"
            ></TheTrackermanagementInfo>
          </div>
          <div class="m-1" v-if="activeview == 'Chipmanagement'">
            <TheChipmanagementAusgabe
              v-if="mode == 'Ausgabe'"
              @modus-updated="modusStatus"
            ></TheChipmanagementAusgabe>
            <TheChipmanagementErsatztransponder
              v-if="mode == 'Ersatztransponder'"
              @modus-updated="modusStatus"
            ></TheChipmanagementErsatztransponder>
            <TheChipmanagementZurueck
              v-if="mode == 'Zurueck'"
              @modus-updated="modusStatus"
            ></TheChipmanagementZurueck>
          </div>
          <div class="m-1" v-if="activeview == 'Einlass'">
            <TheEinlassScannen
              v-if="mode == 'Scannen'"
              @modus-updated="modusStatus"
            ></TheEinlassScannen>
          </div>
        </div>
      </div>
    </div>

    <div
      class="offcanvas offcanvas-end"
      tabindex="-1"
      id="offcanvasExample"
      aria-labelledby="offcanvasExampleLabel"
    >
      <div class="offcanvas-header">
        <h5 class="offcanvas-title" id="offcanvasExampleLabel">Info</h5>
        <button
          type="button"
          class="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div class="offcanvas-body">
        <div class="p-3 pt-0">
          <h3 v-if="appEventName">{{ appEventName }}</h3>
          <span v-if="appEventName"
            >vom {{ appEventVon }} bis {{ appEventBis }} <br
          /></span>

          <br />
          <br />
          <br />
          <select
            v-model="geraet"
            class="form-select"
            :class="[geraet != '' ? 'status_gruen' : 'bg-warning']"
            :selected="geraet == ''"
            @change="set_geraet($event)"
            style="font-size: 1.1em"
            v-if="geraet == ''"
          >
            <option
              v-for="option in scanner_vals"
              :key="option.value"
              :value="option.value"
            >
              {{ option.text }}
            </option>
          </select>
          <select
            v-model="rargeraet"
            v-if="activeMode.includes('RadamRing') && geraet_vals.length > 0"
            class="form-select"
            :class="[rargeraet != '' ? 'status_gruen' : 'bg-warning']"
            :selected="rargeraet == ''"
            @change="set_rargeraet($event)"
            style="font-size: 1.1em"
          >
            <option
              v-for="option in geraet_vals"
              :key="option.value"
              :value="option.value"
            >
              {{ option.text }}
            </option>
          </select>
          <!--Debug: <br />{{ this.browser }}<br />
          <button @click="play" class="btn btn-info my-2">Beep</button><br />
          {{ scanncode.type }}<br /><br />-->

          <button @click="closeButton()" class="btn btn-danger my-2">
            App schließen</button
          ><br />

          <button @click="reloadButton()" class="btn btn-warning my-2">
            App reload</button
          ><br />
          Release: 150325-1706<br />
          <span v-if="geraet">Gerät: {{ geraet }}<br /></span>
          <span v-if="chipLog" @click="changeChipLog()"
            >ChipLog: {{ chipLog }}<br
          /></span>

          <div
            v-if="chipLogKomplett && chipLogKomplett.length > 0 && showChipLog"
          >
            <ul>
              <li v-for="eintrag in chipLogKomplett" :key="eintrag.ti">
                {{ eintrag.bib }} - {{ eintrag.tr }} -
                {{
                  new Date(eintrag.ti).toLocaleString("de-DE", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                  })
                }}
              </li>
            </ul>

            <button
              @click="downloadCSVChip()"
              class="btn btn-warning ms-2 mb-2"
            >
              Download CSV
            </button>
            <button
              @click="
                resetChipLog();
                showChipLog = false;
              "
              class="btn btn-danger ms-2 mb-2"
              v-if="chipReset"
            >
              Reset Chip-Log
            </button>
          </div>
          <div @click="changeUrl()">{{ apiUrl_Show }}</div>
          <a
            href="http://192.168.240.1/"
            style="padding-top: 15px; display: block"
            target="_blank"
            v-if="1 == 2"
            >GaPa Wlan-Login</a
          >
          <button
            @click="refreshApp"
            v-if="updateExists"
            class="btn btn-success my-2"
          >
            Neue Version vorhanden! <br />Klicke für Update
          </button>

          <p class="decode-result" v-if="QRScannerEnabled">
            Letzter scann: <b>{{ QRScannerResult }}</b>

            <qrcode-stream
              :paused="QRScannerPaused"
              @detect="onDetect"
              @error="onError"
              @camera-on="resetValidationState"
            >
            </qrcode-stream>
          </p>
          <button
            @click="QRScannerEnabled = true"
            v-if="
              !QRScannerEnabled &&
              (geraet == 'sonstiges' ||
                geraet == 'Pani-1' ||
                geraet == 'Pani-2')
            "
            class="btn btn-success my-2"
          >
            Kamera aktivieren
          </button>

          <br />
          <br />
          <br />

          <button
            @click="logoutButton()"
            class="btn btn-danger my-2"
            v-if="appEventName"
          >
            EVENT LOGOUT</button
          ><br />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LogRocket from "logrocket";
LogRocket.init("vjfwqa/checkin-app");

// This is an example script - don't forget to change it!
LogRocket.identify(localStorage.geraet, {
  //  name: 'James Morrison',
  //  email: 'jamesmorrison@example.com',
  // Add your own custom user variables here, ie:
  //  subscriptionType: 'pro'
  rrid: localStorage.rrid,
  event: localStorage.eventname,
});
import update from "./mixins/update";
import { defineAsyncComponent } from "vue";
import Store from "@/store";
import TheNavbar from "@/components/TheNavbar.vue";

import TheLogin from "@/components/Login/TheLogin.vue";
import { QrcodeStream } from "vue-qrcode-reader";

const TheSelectionCheckIn = defineAsyncComponent(() =>
  import(
    /* webpackCunkName: "StandartCheckIn" */ "@/components/StandartCheckIn/TheSelectionCheckIn.vue"
  )
);
const TheEinzelCheckIn = defineAsyncComponent(() =>
  import(
    /* webpackCunkName: "StandartCheckIn" */ "@/components/StandartCheckIn/TheEinzelCheckIn.vue"
  )
);
const TheSammelCheckIn = defineAsyncComponent(() =>
  import(
    /* webpackCunkName: "StandartCheckIn" */ "@/components/StandartCheckIn/TheSammelCheckIn.vue"
  )
);
const TheTeamCheckIn = defineAsyncComponent(() =>
  import(
    /* webpackCunkName: "StandartCheckIn" */ "@/components/StandartCheckIn/TheTeamCheckIn.vue"
  )
);
const TheSelectionNachmelden = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "Nachmelden" */ "@/components/StandartNachmelden/TheSelectionNachmelden.vue"
  )
);
const TheEinzelNachmelden = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "Nachmelden" */ "@/components/StandartNachmelden/TheEinzelNachmelden.vue"
  )
);
const TheListeNachmelden = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "Nachmelden" */ "@/components/StandartNachmelden/TheListeNachmelden.vue"
  )
);
const TheDirektNachmelden = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "Nachmelden" */ "@/components/StandartNachmelden/TheDirektNachmelden.vue"
  )
);

const TheSelectionOrderOption = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "OrderOption" */ "@/components/StandartOrderOption/TheSelectionOrderOption.vue"
  )
);
const TheEinzelOrderOption = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "OrderOption" */ "@/components/StandartOrderOption/TheEinzelOrderOption.vue"
  )
);

const TheSelectionBDR = defineAsyncComponent(() =>
  import(/* webpackChunkName: "BDR" */ "@/components/BDR/TheSelectionBDR.vue")
);
const TheBDRTeamCheckIn = defineAsyncComponent(() =>
  import(/* webpackChunkName: "BDR" */ "@/components/BDR/TheBDRTeamCheckIn.vue")
);
const TheBDRRegistrierungCheckIn = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "BDR" */ "@/components/BDR/TheBDRRegistrierungCheckIn.vue"
  )
);
const TheBDRNMCheckIn = defineAsyncComponent(() =>
  import(/* webpackChunkName: "BDR" */ "@/components/BDR/TheBDRNMCheckIn.vue")
);

const TheSelectionGepaeck = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "Gepaeck" */ "@/components/Gepaeck/TheSelectionGepaeck.vue"
  )
);
const TheGepaeckAufAusgabePlatz = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "Gepaeck" */ "@/components/Gepaeck/TheGepaeckAufAusgabePlatz.vue"
  )
);
const TheGepaeckAufAusgabe = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "Gepaeck" */ "@/components/Gepaeck/TheGepaeckAufAusgabe.vue"
  )
);
const TheGepaeckInfo = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "Gepaeck" */ "@/components/Gepaeck/TheGepaeckInfo.vue"
  )
);

const TheSelectionStartkontrolle = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "Startkontrolle" */ "@/components/Startkontrolle/TheSelectionStartkontrolle.vue"
  )
);
const TheStartkontrolleScannen = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "Startkontrolle" */ "@/components/Startkontrolle/TheStartkontrolleScannen.vue"
  )
);
const TheStartkontrolleInfo = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "Startkontrolle" */ "@/components/Startkontrolle/TheStartkontrolleInfo.vue"
  )
);

const TheSelectionRadamRing = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "RadamRing" */ "@/components/RadamRing/TheSelectionRadamRing.vue"
  )
);
const TheRadamRingScannen = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "RadamRing" */ "@/components/RadamRing/TheRadamRingScannen.vue"
  )
);
const TheRadamRingInfo = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "RadamRing" */ "@/components/RadamRing/TheRadamRingInfo.vue"
  )
);

const TheSelectionTrackermanagement = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "Trackermanagement" */ "@/components/Trackermanagement/TheSelectionTrackermanagement.vue"
  )
);
const TheTrackermanagementScannen = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "Trackermanagement" */ "@/components/Trackermanagement/TheTrackermanagementScannen.vue"
  )
);
const TheTrackermanagementInfo = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "Trackermanagement" */ "@/components/Trackermanagement/TheTrackermanagementInfo.vue"
  )
);

const TheSelectionChipmanagement = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "Chipmanagement" */ "@/components/Chipmanagement/TheSelectionChipmanagement.vue"
  )
);
const TheChipmanagementAusgabe = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "Chipmanagement" */ "@/components/Chipmanagement/TheChipmanagementAusgabe.vue"
  )
);
const TheChipmanagementErsatztransponder = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "Chipmanagement" */ "@/components/Chipmanagement/TheChipmanagementErsatztransponder.vue"
  )
);
const TheChipmanagementZurueck = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "Chipmanagement" */ "@/components/Chipmanagement/TheChipmanagementZurueck.vue"
  )
);

const TheSelectionEinlass = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "Einlass" */ "@/components/Einlass/TheSelectionEinlass.vue"
  )
);
const TheEinlassScannen = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "Einlass" */ "@/components/Einlass/TheEinlassScannen.vue"
  )
);

const TheSelectionErgebniss = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "Ergebniss" */ "@/components/Ergebniss/TheSelectionErgebniss.vue"
  )
);

import { useToast } from "vue-toastification";

import dayjs from "dayjs";

import { useSound } from "@vueuse/sound";
import soundBeep from "@/assets/sounds/beep.mp3";

// eslint-disable-next-line
//import * as EB from "./assets/js/ebapi.js";

export default {
  name: "App",
  data() {
    return {
      chars: [],
      barcode: "",
      mode: "0",
      modeEinzel: "",
      browser: "",
      scanner_enable: false,
      scanner_code: new Array(),
      inputSearch: "",

      refreshing: false,
      updateExists: false,
      showChipLog: false,

      chipReset: false,

      QRScannerEnabled: false,
      QRScannerResult: "",
      QRScannerPaused: false,
      QRScannerisValid: undefined,

      geraet: "",
      rargeraet: "",

      scanner_vals: [
        { text: "NFT10-1", value: "NFT10-1" },
        { text: "NFT10-2", value: "NFT10-2" },
        { text: "NFT10-3", value: "NFT10-3" },
        { text: "NFT10-4", value: "NFT10-4" },
        { text: "NFT10-5", value: "NFT10-5" },
        { text: "NFT10-6", value: "NFT10-6" },
        { text: "NFT10-7", value: "NFT10-7" },
        { text: "NFT10-8", value: "NFT10-8" },
        { text: "NFT10-9", value: "NFT10-9" },
        { text: "NFT10-10", value: "NFT10-10" },
        { text: "NFT10-11", value: "NFT10-11" },
        { text: "NFT10-12", value: "NFT10-12" },
        { text: "NFT10-13", value: "NFT10-13" },
        { text: "NFT10-14", value: "NFT10-14" },
        { text: "NFT10-15", value: "NFT10-15" },
        { text: "NFT10-16", value: "NFT10-16" },
        { text: "NFT10-17", value: "NFT10-17" },
        { text: "NFT10-18", value: "NFT10-18" },
        { text: "NFT10-19", value: "NFT10-19" },
        { text: "NFT10-20", value: "NFT10-20" },
        { text: "NFT10-21", value: "NFT10-21" },
        { text: "NFT10-22", value: "NFT10-22" },
        { text: "NFT10-23", value: "NFT10-23" },
        { text: "NFT10-24", value: "NFT10-24" },
        { text: "NFT10-25", value: "NFT10-25" },
        { text: "NFT10-26", value: "NFT10-26" },
        { text: "NFT10-27", value: "NFT10-27" },
        { text: "NFT10-28", value: "NFT10-28" },
        { text: "NFT10-29", value: "NFT10-29" },
        { text: "NFT10-30", value: "NFT10-30" },
        { text: "NFT10-31", value: "NFT10-31" },
        { text: "TC26-1", value: "TC26-1" },
        { text: "TC26-2", value: "TC26-2" },
        { text: "TC26-3", value: "TC26-3" },
        { text: "TC26-4", value: "TC26-4" },
        { text: "TC26-5", value: "TC26-5" },
        { text: "TC26-6", value: "TC26-6" },
        { text: "TC26-7", value: "TC26-7" },
        { text: "TC26-8", value: "TC26-8" },
        { text: "TC26-9", value: "TC26-9" },
        { text: "TC26-10", value: "TC26-10" },
        { text: "Pani-1", value: "Pani-1" },
        { text: "Pani-2", value: "Pani-2" },
        { text: "sonstiges", value: "sonstiges" },
      ],

      toast: useToast(),
    };
  },

  components: {
    TheNavbar,
    TheLogin,
    TheSelectionCheckIn,
    TheEinzelCheckIn,
    TheSammelCheckIn,
    TheTeamCheckIn,
    TheSelectionNachmelden,
    TheEinzelNachmelden,
    TheListeNachmelden,
    TheDirektNachmelden,
    TheSelectionOrderOption,
    TheEinzelOrderOption,
    TheSelectionBDR,
    TheSelectionGepaeck,
    TheGepaeckAufAusgabePlatz,
    TheGepaeckAufAusgabe,
    TheGepaeckInfo,
    TheBDRTeamCheckIn,
    TheBDRRegistrierungCheckIn,
    TheBDRNMCheckIn,
    TheSelectionStartkontrolle,
    TheStartkontrolleScannen,
    TheStartkontrolleInfo,
    TheSelectionRadamRing,
    TheRadamRingScannen,
    TheRadamRingInfo,
    TheSelectionTrackermanagement,
    TheTrackermanagementScannen,
    TheTrackermanagementInfo,
    TheSelectionChipmanagement,
    TheChipmanagementAusgabe,
    TheChipmanagementZurueck,
    TheChipmanagementErsatztransponder,
    TheSelectionEinlass,
    TheEinlassScannen,
    TheSelectionErgebniss,
    QrcodeStream,
  },
  computed: {
    validationSuccess() {
      return this.QRScannerisValid === true;
    },

    validationFailure() {
      return this.QRScannerisValid === false;
    },
    chipLog() {
      if (localStorage.OfflineChipsLog) {
        return JSON.parse(localStorage.OfflineChipsLog).length;
      }
      return false;
    },
    chipLogKomplett() {
      if (localStorage.OfflineChipsLog) {
        return JSON.parse(localStorage.OfflineChipsLog);
      }
      return false;
    },
    apiUrl() {
      return Store.getters.activeAPIUrl();
    },
    apiUrl_Show() {
      if (this.apiUrl == "https://app.pani.cloud") {
        return "Endpoint: Core";
      } else {
        return "Endpoint: Backup";
      }
    },
    geraet_vals() {
      return Store.getters.activeGeraet_auswahl();
    },
    activeMode() {
      return Store.getters.activeMode();
    },
    scanncode() {
      console.log("APP hole daten", Store.getters.activeBarcode());

      return Store.getters.activeBarcode();
    },
    activeview() {
      console.log("APP hole View", Store.getters.activeView());

      return Store.getters.activeView();
    },
    appEventVon() {
      // prüfe ob der Zeitraum passt
      var now = dayjs().unix();
      if (Store.getters.activeEventVon() > now) {
        // Event ist noch nicht aktiv
        this.toast.warning("Event ist noch nicht aktiv!");
        this.logoutButton();
      }
      return dayjs.unix(Store.getters.activeEventVon()).format("DD.MM.YYYY");
    },
    appEventBis() {
      // prüfe ob der Zeitraum passt
      var now = dayjs().unix();
      if (now > Store.getters.activeEventBis()) {
        // Event ist abgelaufen
        this.toast.error("Event ist abgelaufen -> Logout!");
        this.logoutButton();
      }
      return dayjs.unix(Store.getters.activeEventBis()).format("DD.MM.YYYY");
    },
    appEventName() {
      return Store.getters.activeEventName();
    },
  },
  methods: {
    //...mapActions(useCounterStore, ["increment"]),

    async onDetect([firstDetectedCode]) {
      this.QRScannerResult = firstDetectedCode.rawValue;
      this.QRScannerPaused = true;

      //beep
      this.play();

      this.scanner_code["org"] = this.QRScannerResult;
      this.onBarcode({ keyCode: 9999 });

      // pretend it's taking really long
      //await this.timeout(3000);
      //this.QRScannerisValid = this.result.startsWith("http");

      // some more delay, so users have time to read the message
      await this.timeout(2000);
      this.QRScannerPaused = false;
    },

    timeout(ms) {
      return new Promise((resolve) => {
        window.setTimeout(resolve, ms);
      });
    },
    onError: console.error,
    resetValidationState() {
      this.QRScannerisValid = undefined;
    },

    created() {
      document.addEventListener("swUpdated", this.showRefreshUI, {
        once: true,
      });
      if (navigator.serviceWorker) {
        navigator.serviceWorker.addEventListener("controllerchange", () => {
          if (this.refreshing) return;
          this.refreshing = true;
          window.location.reload();
        });
      }
    },
    set_geraet(geraet) {
      console.log(geraet.target.value);
      this.geraet = geraet.target.value;
      localStorage.geraet = geraet.target.value;
      Store.mutations.setGeraet(geraet.target.value);
      window.location.reload();
    },
    set_rargeraet(rargeraet) {
      console.log(rargeraet.target.value);
      this.rargeraet = rargeraet.target.value;
      localStorage.rargeraet = rargeraet.target.value;
      Store.mutations.setRarGeraet(rargeraet.target.value);
    },
    changeUrl() {
      if (this.apiUrl == "https://app1.pani.cloud") {
        Store.mutations.setAPIUrl("https://app.pani.cloud");
      } else {
        Store.mutations.setAPIUrl("https://app1.pani.cloud");
      }
    },
    changeChipLog() {
      this.showChipLog = !this.showChipLog;
    },
    closeButton() {
      window.open("", "_self").close();
    },
    reloadButton() {
      location.reload(true);
    },
    logoutButton() {
      localStorage.uuid = "";
      localStorage.offlineCache = "";
      localStorage.EventVon = "";
      localStorage.EventBis = "";
      localStorage.rrid = "";
      localStorage.mode = "";
      localStorage.eventname = "";
      localStorage.activeView = "";
      location.reload(true);
    },
    modusStatus(modusDO) {
      console.log("Haupt1: ", modusDO);
      //this.play();
      this.mode = modusDO.newModus;
    },
    modusStatusEinzel(modusDO) {
      console.log("Haupt2: ", modusDO);
      //this.modeEinzel = modusDO.newModus;
      this.mode = modusDO.newModus;
    },
    modusStatusBDR(modusDO) {
      console.log("Haupt4: ", modusDO);
      this.mode = modusDO.newModus;
    },
    modusSearchEinzel(modusDO) {
      console.log("Haupt3: ", modusDO);
      if (this.modeEinzel != "") {
        this.mode = this.modeEinzel;
      }
      this.inputSearch = modusDO.newSearch;
    },
    onBarcode(e) {
      //const keypressoutput = document.getElementById("pressed_keys");
      //console.log("pressed: " + e.keyCode);

      if (e.keyCode == 91) {
        //  [ Start-key from DataWedge
        //keypressoutput.innerHTML = "";
        this.scanner_enable = true;
        this.scanner_code["org"] = "";
        e.preventDefault();
      } else if (e.keyCode == 93 || e.keyCode == 9999) {
        // ] End-key from DataWedge
        //keypressoutput.innerHTML += "";
        console.log("alt:", this.scanner_code["org"]);
        if (this.scanner_code["org"].substr(1, 6) == "000026") {
          //console.log("OK Spezial");
          this.scanner_code["org"] = this.scanner_code["org"].substr(7);
          //console.log("neu:", this.scanner_code["org"]);
        }
        this.scanner_code["type"] = this.scanner_code["org"].substr(0, 1);
        //console.log("TEST NICO");
        //console.log(this.scanner_code["org"].split("#"));
        //console.log(this.scanner_code["org"].split("#")[0]);
        //console.log("ENDE TEST NICO");
        // wenn http://abaqr.de/XXXXX#123
        if (
          this.scanner_code["type"] == "h" &&
          this.scanner_code["org"].includes("#") &&
          this.scanner_code["org"].split("#")[0] !=
            "https://checkinapp.datasport.de/"
        ) {
          console.log(this.scanner_code);
          this.scanner_code["org"] = this.scanner_code["org"].split("#");
          console.log("abaqr-spezial", this.scanner_code["org"]);
          // Anpassungen wenn der QR-Code vorschoben ist (z.B. 220 -> 219)
          //if (this.scanner_code["org"][1] > 219) {
          //  this.scanner_code["org"][1] =
          //    parseInt(this.scanner_code["org"][1]) - 1;
          //  console.log("abaqr-spezial +1", this.scanner_code["org"][1]);
          //}
          // Anpassungen wenn der QR-Code vorschoben ist (z.B. 50361 -> 50899)
          //if (this.scanner_code["org"][1] > 50360) {
          //  this.scanner_code["org"][1] =
          //    parseInt(this.scanner_code["org"][1]) + 538;
          //  console.log("abaqr-spezial +538", this.scanner_code["org"][1]);
          //} else if (this.scanner_code["org"][1] > 50348) {
          //  this.scanner_code["org"][1] =
          //    parseInt(this.scanner_code["org"][1]) + 102;
          //  console.log("abaqr-spezial +102", this.scanner_code["org"][1]);
          //} else if (this.scanner_code["org"][1] > 50346) {
          //  this.scanner_code["org"][1] =
          //    parseInt(this.scanner_code["org"][1]) + 101;
          //  console.log("abaqr-spezial +101", this.scanner_code["org"][1]);
          //} else if (this.scanner_code["org"][1] > 50000) {
          //  this.scanner_code["org"][1] =
          //    parseInt(this.scanner_code["org"][1]) + 100;
          //  console.log("abaqr-spezial +100", this.scanner_code["org"][1]);
          //}
          // Fehlerhafter Code: #ST9999 -> #9999
          if (this.scanner_code["org"][1].substr(0, 2) == "ST") {
            this.scanner_code["org"][1] = this.scanner_code["org"][1].substr(2);
            console.log("abaqr-spezial ST", this.scanner_code["org"][1]);
          }
          this.scanner_code["org"] = "#" + this.scanner_code["org"][1];
          this.scanner_code["type"] = this.scanner_code["org"].substr(0, 1);
        }
        // wenn https://checkinapp.datasport.de/#fd21e941-0250-4401-8072-8059da3cf056
        if (
          this.scanner_code["type"] == "h" &&
          this.scanner_code["org"].includes("#") &&
          this.scanner_code["org"].split("#")[0] ==
            "https://checkinapp.datasport.de/"
        ) {
          this.scanner_code["org"] = this.scanner_code["org"].split("#");
          console.log("login-spezial", this.scanner_code["org"]);
          this.scanner_code["org"] = this.scanner_code["org"][1];
          this.scanner_code["type"] = "§";
        }
        // wenn http://tr.racemap.com/XXXXXXXXX
        if (
          this.scanner_code["type"] == "h" &&
          this.scanner_code["org"].includes("racemap") &&
          this.scanner_code["org"].split("/")[2] == "tr.racemap.com"
        ) {
          this.scanner_code["org"] = this.scanner_code["org"].split("/")[3];
          console.log("racemap-spezial", this.scanner_code["org"]);
          this.scanner_code["type"] = "R";
        }
        // wenn http://dsqr.ch/m/XXXXXXX
        if (
          this.scanner_code["type"] == "h" &&
          this.scanner_code["org"].includes("dsqr") &&
          this.scanner_code["org"].split("/")[2] == "dsqr.ch"
        ) {
          this.scanner_code["org"] = this.scanner_code["org"].split("/")[4];
          console.log("dsqr-spezial", this.scanner_code["org"]);
          this.scanner_code["type"] = "M";
        }
        // wenn http://d1.lu/m/XXXXXXX
        if (
          this.scanner_code["type"] == "h" &&
          this.scanner_code["org"].includes("d1") &&
          this.scanner_code["org"].split("/")[2] == "d1.lu"
        ) {
          this.scanner_code["org"] = this.scanner_code["org"].split("/")[4];
          console.log("dsqr-spezial", this.scanner_code["org"]);
          this.scanner_code["type"] = "M";
        }
        this.scanner_code["gsplit"] = this.scanner_code["org"]
          .substr(1)
          .split("|");
        this.scanner_enable = false;
        console.log(this.scanner_code);
        this.browser = this.scanner_code["org"];
        // Prüfen ob Startnummernausgabe, dann erst Mode setzen
        //if (this.modeEinzel != "" && this.scanner_code["type"] == "/") {
        //  this.mode = this.modeEinzel;
        //}
        Store.mutations.setBarcode(this.scanner_code);
        //alert("gescannt");
        //this.play();

        //if (
        //  this.mode == "0" &&
        //  this.scanner_code["type"] == "/" &&
        //  this.activeview != "OrderOption"
        //) {
        //this.mode = "Einzel";
        //console.log("Modus auf Einzel setzen.... ??? WANN????");
        //}
        if (e.keyCode != 9999) {
          e.preventDefault();
        }
      } else if (this.scanner_enable) {
        // Scanner nicht ausgeben
        //keypressoutput.innerHTML += e.key;
        this.scanner_code["org"] += e.key;
        e.preventDefault();
      }
    },

    downloadCSVChip() {
      let csv = "Bib;Transponder;Zeit\n";
      this.chipLogKomplett.forEach((row) => {
        csv +=
          row.bib +
          ";" +
          row.tr +
          ";" +
          new Date(row.ti).toLocaleString("de-DE", {
            day: "2-digit",
            month: "2-digit",
            year: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          }) +
          "\n";
      });

      const anchor = document.createElement("a");
      anchor.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csv);
      anchor.target = "_blank";
      anchor.download = "ChipLog_" + this.geraet + "_" + Date.now() + ".csv";
      anchor.click();

      this.chipReset = true;
    },
    resetChipLog() {
      Store.mutations.deleteOfflineChipsLog();
      window.location.reload();
    },
  },
  created() {
    this.browser = navigator.userAgent.split(")");
    this.browser = this.browser[0].split(";");
    this.browser = this.browser[this.browser.length - 1].trim();
  },
  mounted() {
    window.addEventListener("keypress", this.onBarcode);
    if (localStorage.geraet) {
      this.geraet = localStorage.geraet;
      Store.mutations.setGeraet(this.geraet);
    }
    if (localStorage.rargeraet) {
      this.rargeraet = localStorage.rargeraet;
      Store.mutations.setRarGeraet(this.rargeraet);
    }
  },
  unMounted() {
    window.removeEventListener("keypress", this.onBarcode);
  },
  mixins: [update],
  setup() {
    const { play } = useSound(soundBeep);

    return {
      play,
    };
  },
};
</script>

<style>
#app {
  overflow: hidden;
}
.steps {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  position: relative;
}
.step-button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: none;
  background-color: #999999;
  transition: 0.4s;
  color: white !important;
}
.step-button[aria-expanded="true"] {
  width: 60px;
  height: 60px;
  background-color: #aa1e1e;
  color: #fff;
}
@media only screen and (max-width: 576px) {
  .step-button {
    width: 25px;
    height: 25px;
  }
  .step-button[aria-expanded="true"] {
    width: 30px;
    height: 30px;
  }
}
.done {
  background-color: #aa1e1e;
  color: #fff;
}
.step-item {
  z-index: 10;
  text-align: center;
}
#progress {
  -webkit-appearance: none;
  position: absolute;
  width: calc(100% - 20px);
  z-index: 5;
  height: 10px;
  margin-left: 18px;
  margin-bottom: 18px;
}
/* to customize progress bar */
#progress::-webkit-progress-value {
  background-color: #aa1e1e;
  transition: 0.5s ease;
}
#progress::-webkit-progress-bar {
  background-color: #444f51;
}

.font-h5-lg {
  font-size: 1.9rem;
}
@media only screen and (min-width: 992px) {
  .font-h5-lg {
    font-size: 3rem;
  }
  .font-h6-lg {
    font-size: 2rem;
  }
}
.bg-secondary {
  background-color: #999999;
}

.list-group-flush > .list-group-item {
  border-width: 0 !important;
}
.nav-link {
  color: white !important;
}

.btn {
  --bs-btn-disabled-opacity: 0.2;
}
.text-right {
  text-align: right;
}
.font-gross {
  font-size: 1.5rem !important;
}
.font-gross2 {
  font-size: 1.3rem !important;
}
</style>
