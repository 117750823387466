import { reactive, readonly } from "vue";

const state = reactive({
  activeBarcode: new Array(),
  activeView: "Login",
  activeOrdering: "sort",
  activeOrderOptions: new Array(),
  activeOrderOption: "",
  activeSammelTLNs: [],
  activeOfflineTLNs: [],
  activeRREvent: "",
  activeAPIUrl: "https://app.pani.cloud",
  activeMode: [],
  activeEventName: "",
  activeEventVon: "",
  activeEventBis: "",
  activeGeraet: "",
  activeRarGeraet: "",
  activeGeraet_auswahl: "",
  activeTyp_auswahl: "",
  activeUrsache_auswahl: "",
  activeOnline: true,
  activeChipBlacklist: ["MDEFEKT", "MDEFEKT2"],
});

const getters = {
  activeBarcode: () => state.activeBarcode,
  activeView: () => state.activeView,
  activeOrdering: () => state.activeOrdering,
  activeOrderOptions: () => state.activeOrderOptions,
  activeBDROptions: () => state.activeBDROptions,
  activeOrderOption: () => state.activeOrderOption,
  activeSammelTLNs: () => state.activeSammelTLNs,
  activeOfflineTLNs: () => state.activeOfflineTLNs,
  activeRREvent: () => state.activeRREvent,
  activeAPIUrl: () => state.activeAPIUrl,
  activeMode: () => state.activeMode,
  activeEventName: () => state.activeEventName,
  activeEventVon: () => state.activeEventVon,
  activeEventBis: () => state.activeEventBis,
  activeRarGeraet: () => state.activeRarGeraet,
  activeGeraet: () => state.activeGeraet,
  activeGeraet_auswahl: () => state.activeGeraet_auswahl,
  activeTyp_auswahl: () => state.activeTyp_auswahl,
  activeUrsache_auswahl: () => state.activeUrsache_auswahl,
  activeOnline: () => state.activeOnline,
  activeChipBlacklist: () => state.activeChipBlacklist,

  //activeSammelTLNs: () => {
  //  return state.activeSammelTLNs.sort((a, b) => (a.sort > b.sort ? 1 : -1));
  //},
};

const mutations = {
  setBarcode(view) {
    console.log("STATE: setBarcode", view);
    //console.log("old", state.activeBarcode);
    state.activeBarcode = view;
    //console.log("new", state.activeBarcode);
  },

  setAPIUrl(apiurl) {
    console.log("STATE: setAPIUrl", apiurl);
    //console.log("old", state.activeBarcode);
    state.activeAPIUrl = apiurl;
    //console.log("new", state.activeBarcode);
  },

  setRREvent(rrid) {
    console.log("STATE: setRREvent", rrid);
    //console.log("old", state.activeBarcode);
    state.activeRREvent = rrid;
    //console.log("new", state.activeBarcode);
  },

  setRarGeraet(rargeraet) {
    console.log("STATE: setRarGeraet", rargeraet);
    //console.log("old", state.activeBarcode);
    state.setRarGeraet = rargeraet;
    //console.log("new", state.activeBarcode);
  },

  setGeraet(geraet) {
    console.log("STATE: setGeraet", geraet);
    //console.log("old", state.activeBarcode);
    state.activeGeraet = geraet;
    //console.log("new", state.activeBarcode);
  },

  setEventName(name) {
    console.log("STATE: setEventName", name);
    //console.log("old", state.activeBarcode);
    state.activeEventName = name;
    //console.log("new", state.activeBarcode);
  },

  setEventVon(von) {
    console.log("STATE: setEventVon", von);
    //console.log("old", state.activeBarcode);
    state.activeEventVon = von;
    //console.log("new", state.activeBarcode);
  },

  setEventBis(bis) {
    console.log("STATE: setEventBis", bis);
    //console.log("old", state.activeBarcode);
    state.activeEventBis = bis;
    //console.log("new", state.activeBarcode);
  },
  setMode(mode) {
    console.log("STATE: setMode", mode);
    //console.log("old", state.activeBarcode);
    state.activeMode = mode;
    //console.log("new", state.activeBarcode);
  },

  setActiveView(view) {
    console.log("STATE: schalte um auf:", view);
    if (view != "Login") {
      localStorage.activeView = view;
    }
    state.activeView = view;
  },

  setOrderOptions(ooDO) {
    console.log("STATE: set OrderOptions", ooDO);
    state.activeOrderOptions = ooDO;
  },

  setBDROption(bdrDO) {
    console.log("STATE: set BDROptions", bdrDO);
    state.activeBDROptions = bdrDO;
  },

  setOrderOption(ooDO) {
    console.log("STATE: set OrderOption", ooDO);
    state.activeOrderOption = ooDO;
  },

  setOnline(onofDO) {
    console.log("STATE: set Online", onofDO);
    state.activeOnline = onofDO;
  },

  setGeraet_auswahl(geraetDO) {
    console.log("STATE: set Geraet_auswahl", geraetDO);
    state.activeGeraet_auswahl = geraetDO;
  },

  setTyp_auswahl(typDO) {
    console.log("STATE: set Typ_auswahl", typDO);
    state.activeTyp_auswahl = typDO;
  },

  setUrsache_auswahl(ursacheDO) {
    console.log("STATE: set Ursache_auswahl", ursacheDO);
    state.activeUrsache_auswahl = ursacheDO;
  },

  storeSammelTLNs(sammelDO) {
    if (sammelDO.bib > 0 || sammelDO.bib != "") {
      let activeSammelTLN_temp = getters.activeSammelTLNs();
      let bibname = sammelDO.bib;

      if (sammelDO.sbib == "") {
        sammelDO.sbib = sammelDO.bib;
      }

      let data = activeSammelTLN_temp.filter(
        (element) => element.bib == bibname
      );
      //console.log("STATE CHECK: ", data.length);

      if (data.length == 0) {
        activeSammelTLN_temp.unshift(sammelDO);
        state.activeSammelTLNs = activeSammelTLN_temp;
      }

      console.log("STATE: Nummer hinzufügen im SammelTLNs");

      //console.log("STATE: set SammelTLNs", getters.activeSammelTLNs());
    } else {
      console.log("STATE: Nummer NICHT hinzufügt im SammelTLNs");
    }
  },

  storeOfflineTLNs(offlineDO) {
    if (offlineDO.bib > 0) {
      let activeOfflineTLN_temp = getters.activeOfflineTLNs();
      let bibname = offlineDO.bib;

      if (offlineDO.sbib == "") {
        offlineDO.sbib = offlineDO.bib;
      }

      let data = activeOfflineTLN_temp.filter(
        (element) => element.bib == bibname
      );
      //console.log("STATE CHECK: ", data.length);

      if (data.length == 0) {
        activeOfflineTLN_temp.unshift(offlineDO);
        state.activeOfflineTLNs = activeOfflineTLN_temp;
      }

      //console.log("STATE: Nummer hinzufügen im OfflineTLNs");
    } else {
      console.log("STATE: Nummer NICHT hinzufügt im OfflineTLNs");
    }
  },

  storeOfflineChipsLog(sBib, transponder) {
    if (localStorage.OfflineChipsLog) {
      let OfflineChipsLog = JSON.parse(localStorage.OfflineChipsLog);

      OfflineChipsLog.push({ bib: sBib, tr: transponder, ti: Date.now() });

      localStorage.OfflineChipsLog = JSON.stringify(OfflineChipsLog);
    } else {
      let OfflineChipsLog = [];
      OfflineChipsLog.push({ bib: sBib, tr: transponder, ti: Date.now() });

      localStorage.OfflineChipsLog = JSON.stringify(OfflineChipsLog);
    }
    console.log("STATE: Chip hinzufügen im OfflineChipsLog");
  },

  deleteOfflineChipsLog() {
    if (localStorage.OfflineChipsLog) {
      localStorage.OfflineChipsLog = "";
    }
    console.log("STATE: Chip delete im OfflineChipsLog");
  },

  deleteSammelTLNs(bibDO) {
    let activeSammelTLN_temp = getters.activeSammelTLNs();
    console.log("STORE: suche Index", bibDO);
    //const bibIndex = activeSammelTLN_temp.findIndex(
    //  (element) => element.bib === bibDO
    //);
    let index = activeSammelTLN_temp.findIndex((i) => i.bib == bibDO);
    console.log("STORE: delete Index", index);
    if (index >= 0) {
      let checkbib = activeSammelTLN_temp.filter(
        (element) => element.bib == bibDO
      );
      console.log("STORE: delete Index line", checkbib);

      state.activeSammelTLNs.splice(index, 1);
      console.log(
        "STORE: nach delete Index",
        bibDO,
        getters.activeSammelTLNs()
      );
    } else {
      console.log("STORE: NICHT MEHR IN LISTE!!", bibDO);
    }
  },

  deleteAlleSammelTLNs() {
    //let activeSammelTLN_temp = getters.activeSammelTLNs();
    console.log("STORE: delete alle Index");
    //const bibIndex = activeSammelTLN_temp.findIndex(
    //  (element) => element.bib === bibDO
    //);

    state.activeSammelTLNs = [];
  },

  deleteOfflineTLNs() {
    //let activeSammelTLN_temp = getters.activeSammelTLNs();
    console.log("STORE: delete alle Offline");
    //const bibIndex = activeSammelTLN_temp.findIndex(
    //  (element) => element.bib === bibDO
    //);
    if (localStorage.offlineTLNs) {
      localStorage.offlineTLNs = "";
    }
    state.activeOfflineTLNs = [];
  },

  setStatusSammelTLNs(uciDO, statusDO) {
    let activeSammelTLN_temp = getters.activeSammelTLNs();
    console.log("STORE: suche Index", uciDO);
    //const bibIndex = activeSammelTLN_temp.findIndex(
    //  (element) => element.bib === bibDO
    //);
    const tlnObj = activeSammelTLN_temp.find((i) => i.uci === uciDO);
    tlnObj.status = statusDO;
    tlnObj.sort = tlnObj.bib;
    //tlnObj.sort = Date.now();

    console.log("STORE: setze neuen Status", tlnObj);
  },

  setStatusBibSammelTLNs(bibDO, statusDO) {
    let activeSammelTLN_temp = getters.activeSammelTLNs();
    console.log("STORE: suche Index", bibDO);
    //const bibIndex = activeSammelTLN_temp.findIndex(
    //  (element) => element.bib === bibDO
    //);
    const tlnObj = activeSammelTLN_temp.find((i) => i.bib === bibDO);
    tlnObj.status = statusDO;
    tlnObj.sort = tlnObj.bib;
    //tlnObj.sort = Date.now();

    console.log("STORE: setze neuen Status", tlnObj);
  },

  setStatusSammelTLNBib(bibDO, statusDO) {
    let activeSammelTLN_temp = getters.activeSammelTLNs();
    console.log("STORE: suche Index", bibDO);
    //const bibIndex = activeSammelTLN_temp.findIndex(
    //  (element) => element.bib === bibDO
    //);
    const tlnObj = activeSammelTLN_temp.find((i) => i.bib === bibDO);
    tlnObj.status = statusDO;
    tlnObj.sort = tlnObj.bib;
    //tlnObj.sort = Date.now();

    console.log("STORE: setze neuen Status", tlnObj);
  },

  setContestSammelTLNs(uciDO, contestidDO, contestDO) {
    let activeSammelTLN_temp = getters.activeSammelTLNs();
    console.log("STORE: suche Index", uciDO);
    //const bibIndex = activeSammelTLN_temp.findIndex(
    //  (element) => element.bib === bibDO
    //);
    const tlnObj = activeSammelTLN_temp.find((i) => i.uci === uciDO);
    tlnObj.contestid = contestidDO;
    tlnObj.contest = contestDO;
    //tlnObj.sort = Date.now();
    tlnObj.sort = tlnObj.bib;

    //tlnObj = tlnObj.reduce(function (r, v) {
    //  r[v.index] = v.sort;
    //  return r;
    //}, []);

    console.log("STORE: setze neuen Contest", tlnObj);
  },

  setMannSammelTLNs(uciDO, mannDO) {
    let activeSammelTLN_temp = getters.activeSammelTLNs();
    console.log("STORE: suche Index", uciDO);
    //const bibIndex = activeSammelTLN_temp.findIndex(
    //  (element) => element.bib === bibDO
    //);
    const tlnObj = activeSammelTLN_temp.find((i) => i.uci === uciDO);
    tlnObj.mann = mannDO;

    console.log("STORE: setze neuen Mannschaft", tlnObj);
  },

  setVoranmSpezial_SammelTLNs(uciDO, voranm_DO) {
    let activeSammelTLN_temp = getters.activeSammelTLNs();
    console.log("STORE: suche Index", uciDO);
    //const bibIndex = activeSammelTLN_temp.findIndex(
    //  (element) => element.bib === bibDO
    //);
    const tlnObj = activeSammelTLN_temp.find((i) => i.uci === uciDO);
    tlnObj.voranm_ = voranm_DO;

    console.log("STORE: setze neue Voranmeldung", tlnObj);
  },

  setT1SammelTLNs(bibDO, t1DO) {
    let activeSammelTLN_temp = getters.activeSammelTLNs();
    console.log("STORE: suche Index", bibDO);
    //const bibIndex = activeSammelTLN_temp.findIndex(
    //  (element) => element.bib === bibDO
    //);
    const tlnObj = activeSammelTLN_temp.find((i) => i.bib === bibDO);
    tlnObj.t1 = t1DO;

    console.log("STORE: setze neuen T1", tlnObj);
  },

  setT2SammelTLNs(bibDO, t2DO) {
    let activeSammelTLN_temp = getters.activeSammelTLNs();
    console.log("STORE: suche Index", bibDO);
    //const bibIndex = activeSammelTLN_temp.findIndex(
    //  (element) => element.bib === bibDO
    //);
    const tlnObj = activeSammelTLN_temp.find((i) => i.bib === bibDO);
    tlnObj.t2 = t2DO;

    console.log("STORE: setze neuen T2", tlnObj);
  },

  deleteT2SammelTLNs(bibDO) {
    let activeSammelTLN_temp = getters.activeSammelTLNs();
    console.log("STORE: suche Index", bibDO);
    //const bibIndex = activeSammelTLN_temp.findIndex(
    //  (element) => element.bib === bibDO
    //);
    const tlnObj = activeSammelTLN_temp.find((i) => i.bib === bibDO);
    tlnObj.t2 = "";

    console.log("STORE: setze neuen T2", tlnObj);
  },

  setChipBlacklist(chipDO) {
    console.log("STATE: set ChipBlacklist", chipDO);
    state.activeChipBlacklist = chipDO;
  },

  deleteEvent(dayId, eventTitle) {
    const dayObj = state.calendarWeekData.find((day) => day.id == dayId);
    const eventIndex = dayObj.events.findIndex(
      (event) => event.title === eventTitle
    );
    dayObj.events.splice(eventIndex, 1);
  },
  storeEvent(eventDO) {
    const activeDay = getters.activeDay();
    if (eventDO.title != "") {
      activeDay.events.push({
        title: eventDO.title,
        edit: false,
        color: eventDO.color,
        priority: Number(eventDO.priority),
      });
    }
  },
  setActiveOrdering(ordering) {
    state.activeOrdering = ordering;
  },
  editEvent(dayId, eventTitle) {
    // alle edit-Attribute auf false setzen, damit immer nur eine Event bearbeitet wird
    state.calendarWeekData.map((dayObj) => {
      dayObj.events.map((event) => (event.edit = false));
    });
    // setzte das entsprechene edit-Attribute auf true
    const dayObj = state.calendarWeekData.find((day) => day.id == dayId);
    const eventObj = dayObj.events.find((event) => event.title === eventTitle);
    eventObj.edit = true;
  },
  updateEvent(dayId, oldEventTitle, newEvent) {
    newEvent.title = newEvent.title !== "" ? newEvent.title : oldEventTitle;
    const dayObj = state.calendarWeekData.find((day) => day.id == dayId);
    const eventObj = dayObj.events.find(
      (event) => event.title === oldEventTitle
    );
    eventObj.title = newEvent.title;
    eventObj.priority = Number(newEvent.priority);
    eventObj.edit = false;
  },
  setActiveDay(dayId) {
    state.calendarWeekData.map((dayObj) => {
      dayObj.id === dayId ? (dayObj.active = true) : (dayObj.active = false);
    });
  },
};

export default {
  state: readonly(state),
  getters,
  mutations,
};
